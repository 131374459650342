// config.dev.js

export const API_URL = 'https://doublecash.ltd:8082/';



export const urls = {
  sales: `${API_URL}sales.php`,
  expenses: `${API_URL}expenses.php`,
  deletesale: `${API_URL}deletesale.php`,
  deleteexpense: `${API_URL}deleteexpense.php`,
  fetchsales: `${API_URL}fetchsales.php`,
  fetchexpenses: `${API_URL}fetchexpenses.php`,
  insertdrugs: `${API_URL}insertdrugs.php`,
  fetchdrugs: `${API_URL}fetchdrugs.php`,
  movedrugs: `${API_URL}movedrugs.php`,
  fetchdispensary: `${API_URL}fetchdispensary.php`,
  fetchdispensary2: `${API_URL}fetchdispensary2.php`,
  selldrugs: `${API_URL}selldrugs.php`,
  takedrugs: `${API_URL}takedrugs.php`,
  fetchcontacts: `${API_URL}fetchcontacts.php`,
  addcontact: `${API_URL}addcontact.php`,
  addcontact2: `${API_URL}addcontact2.php`,
  whatsapp: `${API_URL}whatsapp.php`,
  permitadmin: `${API_URL}permitadmin.php`,
  removecontact: `${API_URL}removecontact.php`,
  whatsappall: `${API_URL}whatsappall.php`,
  fetchemployees: `${API_URL}fetchemployees.php`,
  addemployee: `${API_URL}addemployee.php`,
  deleteEmployee: `${API_URL}deleteEmployee.php`,
  closeshift: `${API_URL}closeshift.php`,
  live: `${API_URL}live.php`,
  appointments: `${API_URL}appointments.php`,
  suggest: `${API_URL}suggest.php`,
  confirmappointment: `${API_URL}confirmappointment.php`,
  countappointments: `${API_URL}countappointments.php`,
  statistics: `${API_URL}statistics.php`,
 fetchcredits: `${API_URL}fetchcredits.php`,
 submitpayment: `${API_URL}submitpayment.php`,
 creditsum: `${API_URL}creditsum.php`,
 confirmcredit: `${API_URL}confirmcredit.php`,
 birthdays: `${API_URL}birthdays.php`,
 birthdaycount: `${API_URL}birthdaycount.php`,
 dispensedsold: `${API_URL}dispensedsold.php`,
 takenfromshelf: `${API_URL}takenfromshelf.php`,
 checkshift: `${API_URL}checkshift.php`,
 updatepermissions: `${API_URL}updatepermissions.php`,
code: `${API_URL}code.php`,
permit: `${API_URL}permit.php`,
submitpatient: `${API_URL}submitpatient.php`,
patientnames: `${API_URL}patientnames.php`,
waitingpaymentcount: `${API_URL}waitingpaymentcount.php`,
waitingpayment: `${API_URL}waitingpayment.php`,
payinvestigations: `${API_URL}payinvestigations.php`,
labtestscount: `${API_URL}labtestscount.php`,
pendingexamscount: `${API_URL}pendingexamscount.php`,
pendinglabtests: `${API_URL}pendinglabtests.php`,
submitlabresults: `${API_URL}submitlabresults.php`,
submitradiologyresults: `${API_URL}submitradiologyresults.php`,
countfinishedlabresults: `${API_URL}countfinishedlabresults.php`,
fetchpatientfiles: `${API_URL}fetchpatientfiles.php`,
fetchlabresults: `${API_URL}fetchlabresults.php`,
viewfile: `${API_URL}viewfile.php`,
testsavailable: `${API_URL}testsavailable.php`,
submitchanges: `${API_URL}submitchanges.php`,
submitlabtest: `${API_URL}submitlabtest.php`,
inprocesscount: `${API_URL}inprocesscount.php`,
completedlabnames: `${API_URL}completedlabnames.php`,
awaitingnames: `${API_URL}awaitingnames.php`,
inprocessnames: `${API_URL}inprocessnames.php`,
suggestfiles: `${API_URL}suggestfiles.php`,
radiologytestsavailable: `${API_URL}radiologytestsavailable.php`,
pendingradiologyexams: `${API_URL}pendingradiologyexams.php`,
fetchradiologyresults: `${API_URL}fetchradiologyresults.php`,
submitradiologyexam: `${API_URL}submitradiologyexam.php`,
adddrug: `${API_URL}adddrug.php`,
fetchoriginaldrugs: `${API_URL}fetchoriginaldrugs.php`,
deleteoriginaldrug: `${API_URL}deleteoriginaldrug.php`,
stockworth: `${API_URL}stockworth.php`,
creatdrugorder: `${API_URL}creatdrugorder.php`,
fetchpatientscall: `${API_URL}fetchpatientscall.php`,
security: `${API_URL}security.php`,
allinvestigations: `${API_URL}allinvestigations.php`,
inputInvestigation: `${API_URL}inputInvestigation.php`,
deleteInvestigation: `${API_URL}deleteInvestigation.php`,
calculatebill: `${API_URL}calculatebill.php`,
confirmbill: `${API_URL}confirmbill.php`,
treatmentrow: `${API_URL}treatmentrow.php`,
fetchtreatmentrows: `${API_URL}fetchtreatmentrows.php`,
submitchat: `${API_URL}submitchat.php`,
editcontact: `${API_URL}editcontact.php`,
suggestmarketers: `${API_URL}suggestmarketers.php`,
registerClinic: `${API_URL}registerClinic.php`,
loginClinic: `${API_URL}loginClinic.php`,
fetchclinicname: `${API_URL}fetchclinicname.php`,
fetchperformance: `${API_URL}fetchperformance.php`,
detailedperformance: `${API_URL}detailedperformance.php`,
diseasebargraph: `${API_URL}diseasebargraph.php`,
fetchclinicdetails: `${API_URL}fetchclinicdetails.php`,
fetchpaymentdetails: `${API_URL}fetchpaymentdetails.php`,
flutter_submit: `${API_URL}flutter_submit.php`,
recordtransaction: `${API_URL}recordtransaction.php`,
fetchbalance: `${API_URL}fetchbalance.php`,
fetchsales2: `${API_URL}fetchsales2.php`,
fetchexpenses2: `${API_URL}fetchexpenses2.php`,
changepasswords: `${API_URL}changepasswords.php`,
updatedrugdetails: `${API_URL}updatedrugdetails.php`,
updatestockfigures: `${API_URL}updatestockfigures.php`,
fetchdrugquantity: `${API_URL}fetchdrugquantity.php`,
fetchemployees2: `${API_URL}fetchemployees2.php`,
messagingPermission: `${API_URL}messagingPermission.php`,
fetchpermissions: `${API_URL}fetchpermissions.php`,
pdfscan: `${API_URL}pdfscan.php`,
pdflab: `${API_URL}pdflab.php`,
dashboardtoken: `${API_URL}dashboardtoken.php`,
logout: `${API_URL}logout.php`,
pdfscan2: `${API_URL}pdfscan2.php`,
pdflab2: `${API_URL}pdflab2.php`,
pdfrct: `${API_URL}pdfrct.php`,
gotodoctor: `${API_URL}gotodoctor.php`,
checkcritical: `${API_URL}checkcritical.php`,
submitwalkinpt2: `${API_URL}submitwalkinpt2.php`,
suggestfiles2: `${API_URL}suggestfiles2.php`,
editfile: `${API_URL}editfile.php`,
printfile: `${API_URL}printfile.php`,
fetchpermissions2: `${API_URL}fetchpermissions2.php`,
deletereminder: `${API_URL}deletereminder.php`,
printbill: `${API_URL}printbill.php`,
cancelinvestigation: `${API_URL}cancelinvestigation.php`,
alllab: `${API_URL}alllab.php`,
updatelabresults: `${API_URL}updatelabresults.php`,
allradiology: `${API_URL}allradiology.php`,
updateradiologyresults: `${API_URL}updateradiologyresults.php`,
fetchperformance2: `${API_URL}fetchperformance2.php`,
fetchFPwaiting: `${API_URL}fetchFPwaiting.php`,
fetchFPdetails: `${API_URL}fetchFPdetails.php`,
addFamilyPlanningMethod: `${API_URL}addFamilyPlanningMethod.php`,
allFamilyPlanningMethods: `${API_URL}allFamilyPlanningMethods.php`,
deleteFamilyPlanningMethod: `${API_URL}deleteFamilyPlanningMethod.php`,
payfp: `${API_URL}payfp.php`,
fetchFPstatus: `${API_URL}fetchFPstatus.php`,
awaitingpaymentsFP: `${API_URL}awaitingpaymentsFP.php`,
finishedpayingFP: `${API_URL}finishedpayingFP.php`,
submitFPdrugs: `${API_URL}submitFPdrugs.php`,
printRanking: `${API_URL}printRanking.php`,
performanceTrends: `${API_URL}performanceTrends.php`,
generateObsReport: `${API_URL}generateObsReport.php`,
reportEarlyPregnancy: `${API_URL}reportEarlyPregnancy.php`,
generateObsReport2nd: `${API_URL}generateObsReport2nd.php`,
RxSuggestion: `${API_URL}RxSuggestion.php`,
fetchfemales: `${API_URL}fetchfemales.php`,
AddNewMother: `${API_URL}AddNewMother.php`,
fetchMothers: `${API_URL}fetchMothers.php`,
submitantenataldetails: `${API_URL}submitantenataldetails.php`,
AntenatalDetails: `${API_URL}AntenatalDetails.php`,
AncQuestions: `${API_URL}AncQuestions.php`,
updateobshistory: `${API_URL}updateobshistory.php`,
FetchObsHistory: `${API_URL}FetchObsHistory.php`,
submitAntenatalData: `${API_URL}submitAntenatalData.php`,
submitMaternityInvestigations: `${API_URL}submitMaternityInvestigations.php`,
submitMaternityPelvis: `${API_URL}submitMaternityPelvis.php`,
fetchMaternityInvestigations: `${API_URL}fetchMaternityInvestigations.php`,
fetchMaternityPelvis: `${API_URL}fetchMaternityPelvis.php`,
fetchAntenatalData: `${API_URL}fetchAntenatalData.php`,
updateSocialHistory: `${API_URL}updateSocialHistory.php`,
post_labour: `${API_URL}post_labour.php`,
newborn_care: `${API_URL}newborn_care.php`,
immunizations: `${API_URL}immunizations.php`,
fetchlabouroutcome: `${API_URL}fetchlabouroutcome.php`,
AddCervicalDilation: `${API_URL}AddCervicalDilation.php`,
AddFetalHeart: `${API_URL}AddFetalHeart.php`,
AddContraction: `${API_URL}AddContraction.php`,
AddMothersVitals: `${API_URL}AddMothersVitals.php`,
fetchlabourdilatationdata: `${API_URL}fetchlabourdilatationdata.php`,
fetchLabourFetalVitals: `${API_URL}fetchLabourFetalVitals.php`,
fetchlabourcontractionsdata: `${API_URL}fetchlabourcontractionsdata.php`,
fetchlabourmothersvitals: `${API_URL}fetchlabourmothersvitals.php`,
deliveryAI: `${API_URL}deliveryAI.php`,
AddUrineData: `${API_URL}AddUrineData.php`,
fetchurinedata: `${API_URL}fetchurinedata.php`,
motherAIassesment: `${API_URL}motherAIassesment.php`,
countforme: `${API_URL}countforme.php`,
AIcomments: `${API_URL}AIcomments.php`,
foodrecommendations: `${API_URL}foodrecommendations.php`,
submitchatMother: `${API_URL}submitchatMother.php`,
fetchMaternityTreatment: `${API_URL}fetchMaternityTreatment.php`,
submitchangesmother: `${API_URL}submitchangesmother.php`,
treatmentrowmother: `${API_URL}treatmentrowmother.php`,
fetchtreatmentrowsmother: `${API_URL}fetchtreatmentrowsmother.php`,
fetchmaternityprescriptions: `${API_URL}fetchmaternityprescriptions.php`,
updatematernitycomments: `${API_URL}updatematernitycomments.php`,
fetchmaternitycomments: `${API_URL}fetchmaternitycomments.php`,
labourdetails: `${API_URL}labourdetails.php`,
fetchlabourdetails: `${API_URL}fetchlabourdetails.php`,
fetchemployees3: `${API_URL}fetchemployees3.php`,
creditradiology: `${API_URL}creditradiology.php`,
creditlab: `${API_URL}creditlab.php`,
AIstatistics: `${API_URL}AIstatistics.php`,
fetchannualdata: `${API_URL}fetchannualdata.php`,
AIannualassessment: `${API_URL}AIannualassessment.php`,
printstatistics: `${API_URL}printstatistics.php`,
assignAverage: `${API_URL}assignAverage.php`,
assignAverage2: `${API_URL}assignAverage2.php`,
assignAverage3: `${API_URL}assignAverage3.php`,
assignAverage4: `${API_URL}assignAverage4.php`,
fetchmissingdrugs: `${API_URL}fetchmissingdrugs.php`,
ANCpoints1: `${API_URL}ANCpoints1.php`,
ANCpoints2: `${API_URL}ANCpoints2.php`,

};

